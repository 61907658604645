@tailwind base;
@tailwind components;
@tailwind utilities;

/* /Reponsive/ */

@media (min-width: 768px) {
    .map-container {
        height: 50vh;
    }
    .kWgYDg {
        padding-left: 2rem;
        padding-right: 2rem;
    }
}


/* @media (min-width: 1200px) and (max-width: 768px) {
    .sml-md-space {
        margin-top: 1.8rem;
    }
} */

@media (max-width: 1199px) {
    .smlscreen-nav {
        text-align: center;
        margin-top: 1.2rem !important;
        margin-bottom: 1.2rem;
    }
}

@media (min-width: 768px) and (max-width: 1400px) {
    .breadbox-content {
        align-items: center !important;
        justify-content: flex-start !important;
    }
    .vertical-line {
        height: 93px !important;
        opacity: 1;
        margin-left: 23px !important;
    }
}

@media (max-width: 992px) {
    .breadcrumbs {
        margin-top: 57px;
        background-position: center;
    }
}

@media (max-width: 768px) {
    .ctrl-container {
        position: unset !important;
        bottom: unset;
        display: flex;
        -webkit-box-pack: center;
        justify-content: space-evenly;
        padding: unset;
        align-items: center;
    }
    .inquiry-side-bar {
        display: none;
    }
    .map-container {
        height: 30vh;
    }
    .vertical-line {
        display: none;
    }
    .white-box {
        width: 85%;
        margin-top: 125px !important;
    }
    .breadbox-content {
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
    }
    .smlscreen-align-center {
        align-items: center;
        text-align: center;
    }
    .smlscreen-flex {
        display: flex;
        flex-direction: column;
    }
    .smlscreen-flex-row {
        display: flex;
        flex-direction: row;
        flex-flow: unset !important;
    }
    .smlscreen-mt {
        margin-top: 1.8rem;
    }
    .smlscreen-nav {
        text-align: right;
        margin-top: 1.2rem;
        margin-bottom: 1.2rem;
    }
    .scroll-to-top {
        right: 1px !important;
    }
}

@media (max-width: 1200px) {
    .mission-vision {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: space-around;
        /* align-items: center; */
    }
    .mission-vision-box {
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 1px 1px 8px #0000001f;
        opacity: 1;
        min-height: 490px !important;
        min-width: 360px !important;
        margin-top: 3rem;
    }
    .white-box {
        max-width: 521px;
        margin-top: 40px;
    }
}


/* /Reponsive End/ */


/* 
* {
    outline: 1px solid red;
} */

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
}

.text-center {
    text-align: center;
}

.bg-section {
    background: #ecf1f7;
}

.bg-section-blue {
    background-color: #3973b6;
}

.bg-section-dark-blue {
    background: #245791;
}

.text-color {
    color: #243d63;
}

.full-width {
    padding-left: 0 !important;
    padding-right: 0 !important;
    background-color: #3466a0;
}

.breadcrumbs {
    padding: 85px 0;
    min-height: 300px;
    margin-top: 0px;
    text-align: left;
    /* background: linear-gradient( 0deg, rgba(28, 117, 188, 0.4), rgba(28, 117, 188, 0.4)), url(/src/assets/about-us.jpg); */
    background-size: cover;
}


/* .vertical-line-m {
    width: 11px;
    height: 84px;
    background: #3973b6 0% 0% no-repeat padding-box;
    opacity: 1;
} */

.breadcrumbs h4 {
    margin-top: 5px;
    color: #7c8ba1;
    line-height: 40px;
    font-size: 1.125rem;
}

.breadcrumbs h1 {
    position: relative;
}

.breadcrumbs ol {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0 0 10px 0;
    margin: 0;
    font-size: 14px;
    justify-content: center;
    position: relative;
}

.breadcrumbs ol a {
    color: #ebebeb;
    transition: 0.3s;
    font-size: 15px;
}

.breadcrumbs ol a:hover {
    text-decoration: underline;
}

.breadcrumbs ol li+li {
    padding-left: 10px;
}

.breadcrumbs::before {
    background-color: rgba(84, 116, 184, 0.6);
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.contact-info {
    text-align: left;
    display: flex;
    flex-direction: column;
    margin-top: 20px !important;
    padding: 20px;
}

.contact-info h4 {
    font-size: 18px;
    font-weight: 600;
}

.contact-info .icon {
    display: flex;
    flex-direction: row;
    align-content: flex-start;
}

.contact-info p,
.contact-info p a {
    text-align: unset;
    font-size: 16px;
    font-weight: 500;
    color: #000000;
    opacity: 0.8;
    transition: 0.3s;
}

.contact-info p a:hover {
    color: #03abee;
}

.contact-form {
    padding: 8px 40px 40px;
    /* background: #ffffff 0% 0% no-repeat padding-box; */
    box-shadow: 0px 3px 30px #53749a14;
}

.contact-form input[type="text"],
.contact-form input[type="email"],
.contact-form textarea {
    padding: 0.675rem 0.75rem;
}

.contact-form .react-tel-input .form-control {
    width: 100%;
    height: 45px;
    margin-top: 35px !important;
}

.contact-form h2 {
    margin-top: 14px;
    padding-bottom: 20px;
}

.contact-form .form-control,
.contact-form button {
    margin-top: 35px;
}

.contact-form .MuiInputLabel-shrink {
    padding-left: 7px !important;
    padding-right: 7px !important;
    background-color: #fff !important;
    color: #03abee !important;
}

.contact-form .MuiButton-root {
    font-size: 14px;
    border: none;
    padding: 10px 20px !important;
    background-color: #0064b1;
    border-radius: 6px;
    color: #fff !important;
}

.contact-form .MuiButton-root:hover {
    background-color: transparent;
    color: #0064b1 !important;
    border: 1px solid #0064b1;
}

.breadbox {
    display: flex;
    align-items: center;
    min-height: 248px;
    padding: 40px;
    background: #3973b6bc 0% 0% no-repeat padding-box;
    opacity: 1;
    box-shadow: 0px 3px 6px #00000029;
    width: 100%;
}

.section-blue {
    text-align: left;
    width: 100%;
    background-color: #ecf1f7;
    min-height: 348px;
    padding-top: 40px;
    padding-bottom: 40px;
}

.section-blue p {
    margin-top: 20px;
    text-align: left;
    font-size: 1.125rem;
    color: #7c8ba1;
    line-height: 40px;
    opacity: 1;
}

.breadbox-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.breadbox h2 {
    text-align: left;
    font: normal normal 300 44px/55px Poppins;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
}

.breadbox p {
    text-align: left;
    font-size: 1.125rem;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
}

.vertical-line {
    height: 119px;
    background: white;
    border: 3px solid #ffffff;
    opacity: 1;
}

.diff-section p {
    text-align: left;
    letter-spacing: 0px;
    color: #7c8ba1;
    opacity: 1;
    font-size: 1.125rem;
    line-height: 40px;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.diff-section h4 {
    text-align: left;
    font-size: 1.125rem;
    letter-spacing: 0px;
    color: #7c8ba1;
    opacity: 1;
    line-height: 40px;
    margin-bottom: 0.5rem;
}

.mission-vision {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.mission-vision-box h2 {
    text-align: left;
    padding: 40px;
    font-weight: bold;
    font-size: 2rem;
    letter-spacing: 0px;
    color: #243d63;
    opacity: 1;
}

.mission-vision-box p {
    text-align: left;
    font-size: 1.125rem;
    padding-top: 10px;
    padding-bottom: 40px;
    padding-left: 40px;
    line-height: 30px;
    padding-right: 40px;
    letter-spacing: 0px;
    color: #7c8ba1;
    opacity: 1;
}

.mission-vision-box {
    position: relative;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 1px 1px 8px #0000001f;
    opacity: 1;
    min-height: 490px;
    max-width: 45%;
    padding-left: 10px;
    /* Add left padding to create space for the line */
}

.mission-vision-box::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 20%;
    width: 10px;
    background-color: #3973b6;
    /* Change the color as per your preference */
}


/* .jHMEaP {
    max-width: unset !important;
} */

.width-inquiry {
    max-width: 800px;
}

.white-box {
    max-width: 521px;
    height: 170px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 1px 1px 12px #00000029;
    border-radius: 5px;
    opacity: 1;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.slanted-design {
    transform: skewX(-12deg) translateZ(-10px);
}

.scroll-to-top {
    background-color: initial;
    background: #0064b1 !important;
    border: none;
    border-radius: 7px;
    bottom: 40px;
    box-shadow: none !important;
    cursor: pointer;
    height: 40px;
    position: fixed;
    right: 10px;
    width: 40px;
    z-index: 2;
}

.inquiry-side-bar {
    background-color: initial;
    background: #88C3DC !important;
    border: none;
    bottom: 180px;
    box-shadow: none !important;
    cursor: pointer;
    height: 200px;
    position: fixed;
    left: 0px;
    width: 40px;
    z-index: 2;
}

.text-mode {
    writing-mode: vertical-rl;
    text-orientation: mixed;
    text-align: center;
    margin: 0 auto;
    margin-top: 30px;
    color: #FFFFFF;
    font-style: bold;
}

.btm-line {
    border: 1px solid #ffffff;
    opacity: 1;
    width: 100%;
    height: 3px;
    background-color: white;
}

.copright {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    padding-bottom: 25px !important;
}

.nav-style {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    max-width: 1280px;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-around;
}

.pixeltop-3 {
    padding-top: 3px;
}

.nav-link {
    display: block;
    padding: unset !important;
    color: unset !important;
}
.txt-cent{
    text-align: center !important;
}
.img-contact{
   position: relative;
}
.img-content{
  position: absolute;
  left: 38%;
  top:50%;
  transform: translate(-50%, -50%);

}
.white-overlay{
    position: absolute;
    width: 90%;
    left: 50%;
    max-height: 100%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 20px 20px 20px 20px;
    opacity: 0.9;
    box-shadow: 5px 6px 20px #0000001C;
}
.onoverimg{
    transition: 0.5s;
}
.onoverimg:hover{
    scale: 1.1  ;
    transition: 0.5s ease;
}

/* .social-icons {
    position: absolute;
    right: 80px;
    bottom: 10px;
} */